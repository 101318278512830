




































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { EntityTypes } from '@/helpers/entityType';
import { ERR_DEFAULT_MSG } from '@/helpers/errorHandler';
import { SF_CASE_INSTRUCTIONS_MAXLENGTH } from '@/helpers/formFields';

import bookTicketsMutation from '@/graphql/booking/BookTickets.mutation.gql';

import TheCheckedMarkIcon from '@/components/icons/TheCheckedMarkIcon.vue';
import TheCrossIcon from '@/components/icons/TheCrossIcon.vue';

@Component({
  components: { TheCrossIcon, TheCheckedMarkIcon },
})
export default class LikeToAttend extends Vue {
  @Prop() event!: any;

  public allowPeopleCounts = 1;
  public people = 1;
  public notesModel = '';
  public isMobile: boolean = window.innerWidth < 768;
  public readonly notesFieldMaxlength: number = SF_CASE_INSTRUCTIONS_MAXLENGTH;

  get requestedObjectType() {
    return this.event.entityTypename || this.event.__typename;
  }

  get isRequestedEventType() {
    return this.requestedObjectType === EntityTypes.event;
  }

  get modalTitle() {
    if (!this.isRequestedEventType) {
      return 'ADD GUESTS';
    }
    if (!this.event.isGuestListOpen) {
      return this.bookingTypeTitle;
    }
    return 'RSVP';
  }

  get museumRole() {
    return this.$store.state.museumRole;
  }

  get notesPlaceholder() {
    return this.isRequestedEventType
      ? 'Please let us know if you would like to bring a guest and, subject to availability, we will accommodate'
      : 'Please fill in any other relevant notes';
  }

  get showMessageAboutCostAdditionalFeePerSession() {
    return this.museumRole && this.requestedObjectType === EntityTypes.academy;
  }

  get bookingTypeTitle() {
    return this.event.bookingTypeTitle || "I'd Like to Attend";
  }

  created() {
    if (this.isMobile) {
      this.$store.dispatch('disableScroll');
    }

    if (!this.event.isMembers) {
      this.allowPeopleCounts = 4;
    }
  }

  close() {
    this.$emit('close');

    if (this.isMobile) {
      this.$store.dispatch('enableScroll');
    }
  }

  requestTickets() {
    this.$emit('show-loader');

    this.$apollo
      .mutate({
        mutation: bookTicketsMutation,
        variables: {
          input: {
            object: this.requestedObjectType,
            object_id: this.event.id,
            quantity: this.people,
            user_notes: this.notesModel,
          },
        },
      })
      .then((data: any) => {
        if (data) {
          this.$toast.success('Thank you! The Team will come back to you with confirmation.');
          this.$emit('hide-loader');
          this.$emit('requested');
        }
      })
      .catch((err: any) => {
        this.$emit('hide-loader');
        this.$toast.error(err?.graphQLErrors?.[0]?.message || ERR_DEFAULT_MSG);
      });
  }
}
